import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Tile from './Tile';
import Button from './Button';
import { blue, green, sand } from '../styles/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #253704;
  background: #212023;
  background: #773d1c;
  background: #3b1a0d;
  background: #c61536;
  background: #047ea7;
  background: ${sand};

  @media screen and (min-width: 768px) {
    padding: 10rem 2rem;
  }
`;

const Inner = styled.div`
  max-width: 110rem;
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 5rem;
  }
`;

export const ImageQuery = graphql`
  fragment ImageQuery on File {
    childImageSharp {
      gatsbyImageData(quality: 70)
    }
  }
`;

export default function CardSection() {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "drink-cafune.jpg" }) {
        ...ImageQuery
      }
      image2: file(relativePath: { eq: "arepa.png" }) {
        ...ImageQuery
      }
      image3: file(relativePath: { eq: "take-home.jpg" }) {
        ...ImageQuery
      }
    }
  `);

  return (
    <Container>
      <Inner>
        <List>
          <ListItem>
            <Tile
              image={data.image1.childImageSharp.gatsbyImageData}
              imageAlt="A cup displaying Latte art"
            >
              <h2>Drink</h2>
              <p>
                Whatever you’re thirsty for, we&apos;ve got you covered! Coffee
                with Nutella? Tropical Smoothie? Cold Brew or just a Latte?
                Choose your favourite and sip it through!
              </p>
              <Button
                link="/cafuné-menu.pdf"
                title="Menu"
                target="_blank"
                rel="noreferrer"
              />
            </Tile>
          </ListItem>
          <ListItem>
            <Tile
              image={data.image2.childImageSharp.gatsbyImageData}
              imageAlt="Arepas with green filling"
              backgroundColor={blue}
            >
              <h2>Eat</h2>
              <p>
                South American food is full of flavour and surprises that will
                keep you coming back for more! We’re passionate about the unique
                tastes that the tropical continent has to offer, like Arepas,
                the street food of Colombia and Venezuela, and the Açai sorbet
                eaten in Brazil.
              </p>
              <Button
                link="/cafuné-menu.pdf"
                title="Menu"
                target="_blank"
                rel="noreferrer"
              />
            </Tile>
          </ListItem>
          <ListItem>
            <Tile
              image={data.image3.childImageSharp.gatsbyImageData}
              imageAlt="Cafuné cups placed in a row on top of a counter"
              backgroundColor={green}
            >
              <h2>Take home</h2>
              <p>
                No time to lounge around? Take Cafuné home with you! All our
                menu options are available for take out. You can also buy a
                variety of South American products and our Cafuné coffee bags
                over the counter.
              </p>
            </Tile>
          </ListItem>
        </List>
      </Inner>
    </Container>
  );
}
