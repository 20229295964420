import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/images/logo-stacked.svg';

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  flex: 1;
  width: 100%;
  padding: 2rem 4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40vh;
  min-height: 35rem;
  background: rgba(232, 133, 20, 0.6);
  background: #22222294;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c61536+0,e88513+100 */
  background: rgb(198, 21, 54); /* Old browsers */
  background: -moz-linear-gradient(
    -45deg,
    rgba(198, 21, 54, 1) 0%,
    rgba(232, 133, 19, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(198, 21, 54, 1) 0%,
    rgba(232, 133, 19, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(198, 21, 54, 1) 0%,
    rgba(232, 133, 19, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c61536', endColorstr='#e88513',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  @media screen and (min-width: 768px) {
    height: 70vh;
    min-height: 50rem;
  }
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  margin-bottom: 0;
  text-transform: none;
  letter-spacing: -0.15rem;
  line-height: 4.5rem;

  @media screen and (min-width: 768px) {
    font-size: 6rem;
    line-height: 8rem;
  }
`;

const Logo = styled.img`
  width: 10rem;
  margin-bottom: 2.5rem;
  height: auto;

  @media screen and (min-width: 768px) {
    width: 16rem;
    margin-bottom: 4rem;
  }
`;

const WordSlider = styled.div`
  overflow: hidden;
`;

const Word = styled.span`
  display: block;
`;

export default function Hero() {
  const [word, setWord] = useState('flavours');

  useEffect(() => {
    let i = 0;

    function rotateWord() {
      const words = ['culture', 'taste', 'vibrance', 'flavours'];
      if (i > words.length - 1) i = 0;
      setWord(words[i]);
      i += 1;
    }

    setInterval(rotateWord, 2000);
    return () => clearInterval(rotateWord);
  }, []);

  return (
    <div>
      <Container>
        <Inner>
          <Logo src={logo} alt="Cafuné logo" width="160" height="108" />
          <Title>
            Inspired by the
            <WordSlider>
              <Word> {word} </Word>
            </WordSlider>
            of South America.
          </Title>
        </Inner>
      </Container>
    </div>
  );
}
