import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import CardSection from '../components/CardSection';
import Button from '../components/Button';
import SEO from '../components/Seo';

const Intro = styled.div`
  padding: 4rem 2rem;
  text-align: center;

  @media screen and (min-width: 1000px) {
    padding: 12rem 2rem;
  }
`;

const IntroInner = styled.div`
  max-width: 80rem;
  margin: 0 auto;
`;

export default function Home() {
  return (
    <>
      <SEO title="Home" />
      <Layout>
        <Hero />
        <StaticImage
          src="../assets/images/featured.jpg"
          alt="Cafuné food and drink on a wooden table"
          layout="fullWidth"
          style={{
            maxHeight: '70vh',
          }}
        />
        <Intro>
          <IntroInner>
            <p>
              Based in the very heart of Preston, Cafuné is the first South
              American coffee shop in the UK. We serve the best coffee and food
              that the continent has to offer in a vibrant, welcoming
              atmosphere. No matter the weather, it&apos;s always sunny at
              Cafuné!
            </p>
            <Button link="/about/" title="Read More" />
          </IntroInner>
        </Intro>
        <CardSection />
      </Layout>
    </>
  );
}
