import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { brand } from '../styles/colors';

const sharedStyles = css`
  display: inline-block;
  border: 1px solid;
  border-radius: 3.6rem;
  font-size: ${({ large }) => (large ? '1.7rem' : '1.4rem')};
  padding: ${({ large }) => (large ? '1rem 4rem' : '0.7rem 2rem')};
  cursor: pointer;
  color: ${({ secondary }) => (secondary ? 'white' : brand)};
  transition: all 0.3s;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: ${({ secondary }) => (secondary ? 'white' : brand)};
    color: ${({ secondary }) => (secondary ? brand : 'white')};
    border-color: ${({ secondary }) => (secondary ? 'white' : brand)};
  }
`;

const StyledButton = styled.a`
  ${sharedStyles}
`;

const StyledGatsbyButton = styled(Link)`
  ${sharedStyles}
`;

export default function Button({ title, link, secondary, large, target, rel }) {
  const file = /\.[0-9a-z]+$/i.test(link);

  if (file) {
    return (
      <StyledButton
        href={link}
        secondary={secondary}
        large={large}
        target={target}
        rel={rel}
      >
        {title}
      </StyledButton>
    );
  }

  return (
    <StyledGatsbyButton
      to={link}
      secondary={secondary}
      large={large}
      target={target}
      rel={rel}
    >
      {title}
    </StyledGatsbyButton>
  );
}
