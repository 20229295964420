import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { orange } from '../styles/colors';

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 2rem 4rem;
  flex-basis: 60%;
  color: ${({ color }) => color};

  h2,
  a {
    color: ${({ color }) => color};
  }

  a:hover {
    border-color: ${({ color }) => color};
    background: ${({ color }) => color};
    color: ${({ backgroundColor }) => backgroundColor};
  }

  @media screen and (min-width: 768px) {
    padding: 8rem;
    order: 0;
  }
`;

export default function Tile({
  children,
  image,
  backgroundColor = orange,
  color = 'white',
  imageAlt,
}) {
  return (
    <StyledCard backgroundColor={backgroundColor} color={color}>
      {image && (
        <GatsbyImage
          image={image}
          style={{ flexBasis: '50%' }}
          alt={imageAlt}
        />
      )}
      <Inner backgroundColor={backgroundColor} color={color}>
        {children}
      </Inner>
    </StyledCard>
  );
}
